import React from "react";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";

const Handcrafted = ({
  data,
  color = "text-white",
  bgSize = "",
  minH,
  bgUrl,
  btnSpace = "pt-[28px]",
}) => {
  return (
    <div
      className={`relative block w-full mx-auto box-border ${bgSize} show`}
      style={{
        backgroundImage: bgUrl ? bgUrl : "",
      }}
    >
      <div className="flex flex-col flex-wrap justify-center px-[10px]">
        <div className="relative block max-w-[1140px] w-full mx-auto">
          <div
            className={`w-full flex flex-col flex-wrap  ${
              minH ? minH : "sm:min-h-[86vh]"
            } justify-center py-5 px-[10px] ${color}`}
          >
            {/* Heading */}
            <div className="relative block pb-8">
              <h2 className="text-[35px] sm:text-[40px] md:text-[50px] leading-[50px] md:leading-[63px] text-white font-bold text-center">
                {data?.heading}
              </h2>
            </div>
            {/* Content */}
            <div className="max-w-[840px] relative mx-auto">
              <p className="text-sm sm:text-base leading-[26px] text-white font-semibold">
                <span className="block">{data?.comment}</span>
                <br />
                <br />
                {data?._rawCotent && (
                  <PortableText
                    content={data?._rawCotent}
                    className="font-semibold text-sm sm:text-base text-white block-content max-w-none"
                  />
                )}
              </p>
            </div>
            {/* Buttons Container */}
            <div className={`w-[95%] mx-auto ${btnSpace}`}>
              <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
                {/* Button 1 */}

                {data?.ctaButton1?.title && (
                  <div
                    className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
                flex flex-col flex-wrap justify-start relative"
                  >
                    <div className="relative block pt-5 pb-10 m-[1px]">
                      <Button
                        btnText={data?.ctaButton1?.title}
                        btnLink={data?.ctaButton1?.link}
                        btnType={data?.ctaButton1?.variant}
                        linkType={data?.ctaButton1?.type}
                        formId={data?.ctaButton1?.formId}
                        downloadLink={data?.ctaButton1?.downloadLink}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Handcrafted;
